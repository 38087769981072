'use strict';

const MarkerClusterer = require('@google/markerclusterer');

const MapRenderer = {};

MapRenderer.initMap = () => {
    const $map = $('.map-canvas');
    if ($map.data('has-google-api')) {
        const locations = $map.attr('data-locations');
        MapRenderer.maps(locations);
    }
};

MapRenderer.onClickShowMap = () => {
    $(document).on('click', '.js-showMap', function () {
        const $this = $(this);
        $this.parent().addClass('d-none');
        MapRenderer.initMap();
    });
};

MapRenderer.maps = (locations) => {
    const markers = [];
    const infowindow = new google.maps.InfoWindow();
    const markerImageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAoCAYAAADpE0oSAAAAAXNSR0IArs4c6QAABDxJREFUWAm1V11oVEcUPjN377r+RFNopBjZTRYJFsGHClrdRVZUrKj4IH2z2AcVFX1QBFGLD4VCEWq0Pok++iCIvigoFJMbdxOiKFJpKw0Ys5sYSqIY17+QjTs957Kz3L25d2Zi1oFlZs75zvnumZlzZpaBQctkMpESL20EAZtZWawAxpJo1lgxHQMh+gVnD4HBLbts/+E4zqTOLVMBkHBeSZQOIeiwANGkwkodAzYqANptZp/HD3gr5f4+lDiVSX3LQFwVAhb7jUzmjMGQAPZ9t9PdG4S3goRIehCX7wrqvgjSG8rmI25XvDX+anBg8L7fZkrESHoRSXf7gTOaM3YJI9/j9VETcSXSn7yAOo2/wchfeiOvRkx7ipHeRSK7TmR+NyXMhrVyz11iOr2TMPHkUw+SnyFsTgcuAtGv6bRzAlHKTIeU4+fGF467PxqbNuIgLsIzKg6TojRskqdfzi/BkR39sLJtDGLRjy7f+IQF9/sa4cy1JLwo6neJ8jzC7EVWc7J5E+7tXt1Xr146Buf2/w1tze8gYmGJqDQaJxZ+gC2rRqB/eC4MvYhJVVg/t2yVezmVwTCElFOkp3b2QcPs8EpIOsIQVtuQk7u1V4Ok5VWRSnPCEFbXiJNXCn4olg4P7alpI6z2wOElQ6da3jKBvhc3jVcPUiDAJ6RDRzaa1uimkwb0WdR0uF6pPA+NxoBSxrQRlmyUDTlxj2FYBSpj5lCemrZ7/zYC2SgbcmLE4p4ShEoqDm8+RHQwF9N+PanFESfHJ8sdHZIq0s+X25Tk9GGEMalexOmWzBJMDOBeN+s+oB4lE7f2uQ3RFrfEpzPpY0KUf9URSz3lqUwZOkjaPZWG2HMOx7KdPafddIpA5Bx+yYBHrxwSUWEk5v6mQ0ocloj+Ts5dYrwfxzGKo0q2OiiJg7jIVc1tms6kbgkhvqsDxxQXjLHbOae7eiHVVC4eteh6LE6xmrmgWPFd9VRTkvJP88VEMjGCeba9iqjDgHF+IHsn2+V1VbPUUpHOrLmBz5Stcj6THt9ZN3NOzza/j5qllkp8kO3B3X8p55/cow/XV4CDQGI8ef8xwfcH4Kcl4mDtI19BRjV77AUU8oV/8BG+BGXLvXLjMWOXc07ulzB8YMQSbION/xTZoJyb9mRDtiq8khiXacyKwC50oLvovByCW9aPZOsV+sehSy2B+WeDAy2t8XnIvEbKVD0D3p51shdUGNIpI5bGXzUtOomV5085D+0Z/NUwp+FEqN6jCMxjj746TK1LLWMCHmBJDXvXTGChWJnrzOk/EL1ql1oy41/M0UQi/h6XfJOUeXt8Qx3HWnzdK1ONjZZaOrjr5M7ivdIp57LH6tS1ft3G3+TcpDdeauksvSEdh8nyYyypCyqy4iwrtryjoyMvMSa98VJLZ4X+wutEa8sjzLAMRlrk3Pqhq7ProdSb9v8DjuN3VSaIbtUAAAAASUVORK5CYII=';

    // Init  Belgium & Netherlands map in the center of the viewport
    const latlng = new google.maps.LatLng(51.2194475, 4.4024643);
    const mapOptions = {
        scrollwheel: false,
        zoom: 6,
        maxZoom: 17,
        center: latlng,
        mapTypeId: google.maps.MapTypeId.ROADMAP
    };

    const map = new google.maps.Map($('.map-canvas')[0], mapOptions);
    const parsedLocations = JSON.parse(locations);
    const bounds = new google.maps.LatLngBounds();

    if (parsedLocations) {
        Object.keys(parsedLocations).forEach(function (key) {
            var item = parsedLocations[key];
            var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
            var marker = new google.maps.Marker({
                position: storeLocation,
                map: map,
                title: item.name,
                icon: markerImageUrl
            });

            marker.addListener('click', () => {
                if (item.storeType === 'pickUpPoint' || item.storeType === 'job') {
                    infowindow.setOptions({
                        content: item.infoWindowHtml
                    });
                    infowindow.open(map, marker);
                } else {
                    $.ajax({
                        url: item.infoWindowUrl,
                        type: 'get',
                        success: (data) => {
                            if (!data.error) {
                                infowindow.setOptions({
                                    content: data.infoWindowHtml
                                });
                                infowindow.open(map, marker);
                            }
                        }
                    });
                }
            });

            // Create a minimum bound based on a set of storeLocations
            bounds.extend(marker.position);

            // Add marker to array to use with cluster
            markers.push(marker);
        });
    }

    // Fit the all the store marks in the center of a minimum bounds when any store has been found.
    if (parsedLocations && parsedLocations.length !== 0) {
        map.fitBounds(bounds);

        if (parsedLocations.length > 1) {
            MapRenderer.initMarkerClusterer(map, markers);
        }
    }
};

MapRenderer.initMarkerClusterer = (map, markers) => {
    const markerClustererImageUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAwlJREFUWAnNmT1MVEEQx2cWOYNQYyRBMUdppxUHyYPiWj/ixVjZG620lmhhpZ0xtJbmjMbG4hK5l5xHZ2FCYsMlJiRHoL6DeMit899jN+++PzjudhPYefN2d35vlv2YgWnAsra2FK9ouk1VvklMc6TlBzWKpqLIRVMr/TPG9HVzc6swiCnup1MymZw9Oi4/qWp9V4zf6KevAG8r5i9Tk9PvMpnMQa99ewIMgmDmH1WeEfFzrfVMr4O3asfMJXHxmwsUexuGocidS1fAxGriHmn9Xjx2ufNQfb5l2ifmx/ls/nOnnqrdS/EULwdL61TVn4YOB6P4YBkbNmCrHUfLF6lUamrvoPhBk0616zhMPROnr8zOPUqn00eN4zZ5EF8zSjgAwRHGZgtPNgGurCZejMpzUW/BJmxHdZDrptgsCPzNNegbO53jsybF96MLxwFiKzmmys65LIh+vkhW9yTFFu0W5KbY7HPD3kr6AbNthaG259YUxoM4IQ4r5YIskDNtwtbGWWts5pdi03GcOMaDh5XSU1/g8HFgwZEK2QBqojt48KmY816AGLeSvye04xOcZbk4QYvKXJmsxrMabMrc5zwDczhy11Tukum0HglyAVayMdduwR5xORRh89+DWk5pR+yZADYlR8meZ1wOB2yY4qLT+CYIGxaJv4DCpkjiVt8c53iETSGodgrPBLApE/FLUO0ZG+7022AztxlE/L4BWiYDiHRELeL3AxMsYALNBH4VCoXy1YX5mIgBnsddmOl19nv4DRzGgxCQK5F534c81iIMhuUUwgGaKEpyJaIf59Gnka+xER0YzRSfwtLun93f167P4zGwulHWMrUv8+HWRtSmi4utEqmPlWD546izC8jP5MIfD2SB1M2gm2ILiAZI5KCD1Z13bZNHjXCw2+RBC2M8iTyNpvVO7Wz7AWuNac1l869awWHMtoDW4LgTmHWLxEJFayyc+EJ8Q/NJRb7ylrzDfjlwkTFK2Ock//IwF+Z+dRuoqwejA9RSJJKFQKDvUxI9CmnlUf0b4j8MijIk5EL5mAAAAABJRU5ErkJggg==';
    const markerClustererStyles = [
        {
            textColor: '#ffcc00',
            textSize: 16,
            url: markerClustererImageUrl,
            height: 40,
            width: 40
        }
    ];
    const markerClusterer = new MarkerClusterer(map, markers);
    markerClusterer.setStyles(markerClustererStyles);
};

module.exports = MapRenderer;
