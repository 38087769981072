'use strict';

var allJobs = [];
const { default: PerfectScrollbar } = require('perfect-scrollbar');
const MapRenderer = require('../util/mapRenderer');

/**
 * builds the sideNav active filters
 * @param {Object} activeFilters the selected filters by the user as object with key category and value the criterion.
 */
function activeFiltersHtml(activeFilters) {
    $('#js-activeFilters ul').empty();

    Object.entries(activeFilters).forEach(
        ([key, critGroup]) => {
            critGroup.forEach(function (crit) {
                var $critGroup = $(`.navGroup[data-group="${key}"]`);
                var $filter = $critGroup.find(`.filter[data-criteria="${crit}"]`);
                if ($filter.length) {
                    $('#js-activeFilters ul').append(`<li class="filter-bar__value filter-value" data-key="${key}" data-value="${crit}"><a href="#" class="filter-remove">${crit}</a></li>`);
                }
            });
        }
    );
}

/**
 * shows jobs based on the current user filter.
 * @param {Array} filteredJobs array of filtered jobs to show.
 */
function filteredJobsHtml(filteredJobs) {
    $('.job_preview').each(function () {
        if (!filteredJobs.includes($(this).attr('id'))) {
            $(this).hide();
        } else if ($(this).css('display') === 'none') {
            $(this).show();
        }
    });
}

/**
 * returns true or false based on the user selected filters compared whit the job criteria.
 * @param {Array} jobCrits array containing job objects criteria.
 * @return {boolean} true if All criteria match false if not.
 */
function matchCriteria(jobCrits) {
    var userCriteria = JSON.parse(localStorage.getItem('filters'));
    // validCriteria keeps count of matched criteria to check if all where matched.
    var validCriteria = 0;

    var userCriteriaAmount = 0;

    Object.keys(userCriteria).forEach(function (e) {
        if (userCriteria[e].length) {
            userCriteriaAmount++;
        }
    });

    Object.entries(userCriteria).forEach(
        // eslint-disable-next-line no-unused-vars
        ([i, crit]) => {
            crit.forEach(function (c) {
                if (jobCrits.filter(jobCrit => (jobCrit.value === c)).length !== 0) {
                    validCriteria++;
                }
            });
        }
    );

    return validCriteria === userCriteriaAmount;
}

/**
 * gets all the possible filter criteria.
 * @param {Object} jobs all the available jobs.
 * @returns {Object} the filter criteria and their occurrence, array containing the id's of the jobs matching the current criteria.
 */
function getAllFilterJobs(jobs) {
    var filteredJobs = [];
    jobs.forEach(job => {
        if (matchCriteria(job.customFields) || jQuery.isEmptyObject(JSON.parse(localStorage.getItem('filters')))) {
            filteredJobs.push('' + job.id);
        }
    });

    if (filteredJobs.length === 0) {
        $('.jobs-search__no-result').show();
    } else {
        $('.jobs-search__no-result').hide();
    }

    return {
        filteredJobs: filteredJobs
    };
}

/**
 * updates the user view to match the new criteria.
 * @param {Object} key Contains the group.
 * @param {Object} value Contains the key.
 */
function updateUnselected(key, value) {
    var $critGroup = $(`.navGroup[data-group="${key}"]`);
    if ($critGroup.find('.filter.selected').length === 1) {
        $critGroup.find('.refinement').removeClass('active');
    }
    var $filter = $critGroup.find(`.filter[data-criteria="${value}"]`);
    $filter.removeClass('selected');
}

/**
 * updates the user view to match the new criteria.
 * @param {Object} userFilters Contains the current user selected filters.
 */
function updateView(userFilters) {
    var filteredContent = getAllFilterJobs(allJobs);
    filteredJobsHtml(filteredContent.filteredJobs);
    activeFiltersHtml(userFilters);
}

/**
 * limits the given jQ element to the specified character limit.
 * @param {jQuery} $element The element of wich the text will be limited.
 * @param {number} maxCount the amount of character to display.
 */
function limitChars($element, maxCount) {
    var str = String($element.html());
    var tot = str.length;
    str = (tot <= maxCount) ? str : str.substring(0, (maxCount + 1)) + '...';
    $element.html(str);
}

jQuery.expr[':'].icontains = function (a, i, m) {
    return jQuery(a).text().toUpperCase()
        .indexOf(m[3].toUpperCase()) >= 0;
};

$(function () {
    var psJob = null;

    $('body').on('click', '.custom-dropdown__head', function () {
        var $customDropdown = $(this).parents('.custom-dropdown');
        var $customDropdownBody = $customDropdown.find('.custom-dropdown__content');

        $('.custom-dropdown').not($(this).parent()).removeClass('open').find('.custom-dropdown__content')
            .hide();
        if (psJob != null) {
            psJob.destroy();
            psJob = null; // garbage collection
        }
        $customDropdownBody.toggle();
        $customDropdown.toggleClass('open');
        const id = $customDropdown.attr('id');
        const item = document.querySelector(`#${id} > .custom-dropdown__content > ul:not(.ps)`);
        psJob = new PerfectScrollbar(item, { suppressScrollX: true, scrollYMarginOffset: 10 });
    });

    $('body').on('click', '.custom-dropdown__content button', function () {
        var $customDropdown = $(this).parents('.custom-dropdown');
        var $customDropdownBody = $customDropdown.find('.custom-dropdown__content');
        $customDropdownBody.toggle();
        $customDropdown.toggleClass('open');
    });

    $('body').click(function (event) {
        var $target = $(event.target);
        var $customDropdown = $('.custom-dropdown');
        if ($target.parents('.custom-dropdown').length === 0) {
            $customDropdown.removeClass('open').find('.custom-dropdown__content').hide();
            if (psJob != null) {
                psJob.destroy();
                psJob = null;
            }
        }
    });

    $('body').on('click', '.custom-dropdown--selected-value .custom-dropdown__content a', function () {
        var $this = $(this);

        if ($this.hasClass('disabled')) {
            return;
        }

        var $customDropdown = $this.parents('.custom-dropdown');
        $this.toggleClass('selected');

        if (!$customDropdown.find('a.selected').length && $customDropdown.hasClass('active')) {
            $customDropdown.removeClass('active');
        } else if ($customDropdown.find('a.selected').length && !$customDropdown.hasClass('active')) {
            $customDropdown.addClass('active');
        }
    });

    /**
     * gets all the jobs from the data attribute.
     */
    $('.job_preview').each(function () {
        allJobs.push($(this).data('job'));
    });

    localStorage.setItem('filters', JSON.stringify({}));
    updateView({});

    /**
     * adds the user selected filter to global filters.
     */
    $('body').on('click', '.filter, filter i', (e) => {
        e.preventDefault();
        var userFilters = JSON.parse(localStorage.getItem('filters'));

        const group = $(event.target).closest('.navGroup').data('group').trim();
        const criteria = $(event.target).closest('.filter').data('criteria').trim();

        if (!userFilters[group]) {
            userFilters[group] = [];
        }

        if (userFilters[group].indexOf(criteria) >= 0) {
            var tempArr = userFilters[group];
            tempArr.splice(userFilters[group].indexOf(criteria), 1);
            userFilters[group] = tempArr;
        } else {
            var tempArr2 = userFilters[group];
            tempArr2.push(criteria);
            userFilters[group] = tempArr2;
        }

        localStorage.setItem('filters', JSON.stringify(userFilters));
        updateView(userFilters);
    });

    /**
     * removes the user selected filter from global filters.
     */
    $('body').on('click', '.filter-remove', function (e) {
        e.preventDefault();
        var userFilters = JSON.parse(localStorage.getItem('filters'));

        const group = $(event.target).parent().data('key');
        const criteria = $(event.target).parent().data('value');

        updateUnselected(group, criteria);

        if (!userFilters[group]) {
            userFilters[group] = [];
        }

        if (userFilters[group].indexOf(criteria) >= 0) {
            var tempArr = userFilters[group];
            tempArr.splice(userFilters[group].indexOf(criteria), 1);
            userFilters[group] = tempArr;
        }

        localStorage.setItem('filters', JSON.stringify(userFilters));
        updateView(userFilters);
    });

    /**
    * Limit the amount of chars in preview text.
    */
    $('.job_preview_content').each(function () {
        limitChars($(this), 200);
    });

    $('input.job-search').on('keyup keydown', function () {
        var $this = $(this);

        var userFilters = JSON.parse(localStorage.getItem('filters'));

        if ($this.val().length >= 2) {
            $(`.job_preview:icontains('${$this.val()}')`).show();
            updateView(userFilters);
            $(`.job_preview:not(:icontains('${$this.val()}'))`).hide();
        } else {
            updateView(userFilters);
        }

        if ($('.job_preview:visible').length === 0) {
            $('.jobs-search__no-result').show();
        } else {
            $('.jobs-search__no-result').hide();
        }
    });

    $('#job-search-filter-button').click(() => {
        $('#js-navWrapper').toggleClass('d-none');
    });

    /**
     * Function to set the selected filters and apply the filters after refresh
     */
    function setSelected() {
        var userCriteria = JSON.parse(localStorage.getItem('filters'));

        Object.entries(userCriteria).forEach(
            ([key, critGroup]) => {
                critGroup.forEach(function (crit) {
                    var $critGroup = $(`.navGroup[data-group="${key}"]`);
                    $critGroup.find('.refinement').addClass('active');
                    var $filter = $critGroup.find(`.filter[data-criteria="${crit}"]`);
                    $filter.addClass('selected');
                });
            }
        );
    }

    setSelected();

    MapRenderer.onClickShowMap();
});
