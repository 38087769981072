'use strict';

module.exports = {
    init: function () {
        $(() => {
            if ($('#js-spontaneousFormContainer').hasClass('d-none')) {
                cxsForm.init({
                    target: '#js-spontaneousFormContainer',
                    server: 'https://connexys-9566.my.salesforce-sites.com/',
                    formId: 'a000O00000shScd',
                    lang: $('html').attr('lang') === 'nl' ? 'nl_NL' : 'fr'
                });

                $('#js-spontaneousFormContainer').removeClass('d-none');
                $('#js-spontaneousFormContainer')[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        });
    }
};
