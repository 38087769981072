'use strict';

module.exports = {
    init: function () {
        $('head').append('<script src="https://connexys-9566.my.salesforce-sites.com/resource/cxsrec__cxsForm" type="text/javascript"/></script>');

        $(document).on('click', '.js-showApplication', function () {
            if ($('#js-formContainer').hasClass('d-none')) {
                $.spinner().start();
                cxsForm.init({
                    target: '#js-formContainer',
                    server: 'https://connexys-9566.my.salesforce-sites.com/',
                    jobId: $('.job').data('id'),
                    lang: $('html').attr('lang') === 'nl' ? 'nl_NL' : 'fr',
                    onInit: function () {
                        $.spinner().stop();
                    }
                });

                setTimeout(function () {
                    $.spinner().stop();
                }, 3000);

                $('#js-formContainer').removeClass('d-none');
                $('#js-formContainer')[0].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        });
    }
};
